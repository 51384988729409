import '../../common/js/common';
import './frame-portal.scss';
import Vue from 'vue';
import header from '../header/header-portal/header-portal';
import footer from '../footer/footer-portal/footer-portal';
import moment from 'moment';
import i18n from '../../common/js/lang/i18n';
let lang = document.documentElement.lang || 'hu';
moment.locale(lang);

/* eslint-disable no-new*/
new Vue({
	...header,
	i18n
});
new Vue({
	...footer,
	i18n
});
/* eslint-enable*/
