import { computed, ref, watch } from 'vue';
const SECTIONS = {
	NAVIGATION: Symbol('NAVIGATION'),
	SEARCH: Symbol('SEARCH')
};
const HAMBURGER_COLOR_CLOSED = '#FFFFFF';
const HAMBURGER_COLOR_OPENED = '#1B1F27';
export const useMenuBar = (locale) => {
	const mainSearch = ref(null);
	const toggledSection = ref(null);
	const navigationbarVisible = computed(() => {
		return toggledSection.value == SECTIONS.NAVIGATION;
	});
	const sectionsClosed = computed(() => {
		return toggledSection.value == null;
	});
	const searchbarVisible = computed(() => {
		return toggledSection.value == SECTIONS.SEARCH;
	});
	const searchToggle = () => {
		toggledSection.value = toggledSection.value == SECTIONS.SEARCH ? null : SECTIONS.SEARCH;
	};
	const hamburgerStrokeColor = computed(() => {
		return navigationbarVisible.value || searchbarVisible.value ? HAMBURGER_COLOR_OPENED : HAMBURGER_COLOR_CLOSED;
	});
	const navigationToggle = () => {
		toggledSection.value = toggledSection.value == SECTIONS.NAVIGATION ? null : SECTIONS.NAVIGATION;
	};
	const focusInput = () => {
		mainSearch.value.$refs['search-input'].$refs['search-input'].focus();
	};
	const isHungarian = computed(() => locale.value == 'hu');
	watch([navigationbarVisible, searchbarVisible], ([navbar, searchbar]) => {
		if (navbar || searchbar) {
			document.body.classList.add('mobile-menu-active');
		} else {
			document.body.classList.remove('mobile-menu-active');
		}
	});
	return {
		mainSearch,
		sectionsClosed,
		searchbarVisible,
		hamburgerStrokeColor,
		isHungarian,
		navigationbarVisible,
		focusInput,
		navigationToggle,
		searchToggle
	};
};
