import VueSearch from '@ui-common/vue-search';
import debounce from 'lodash.debounce';
import searchApi from '../../../../api/search-api';

const SEARCH_MIN_CHARS = 3;

export default {
	data: () => ({
		items: [],
		model: {
			token: null
		}
	}),
	props: {
		searchPageUrl: {
			type: String,
			required: true
		},
		branchAtmPageUrl: {
			type: String,
			required: true
		},
		siteId: {
			type: String,
			required: true
		}
	},
	components: {
		VueSearch
	},
	methods: {
		update: debounce(function () {
			if (this.model.token.length >= SEARCH_MIN_CHARS) {
				searchApi.getSuggestions(this.model.token, this.$i18n.locale).then((response) => {
					if (this.model.token.length >= SEARCH_MIN_CHARS) {
						this.items = response.data.map((hit) => ({ label: hit }));
					} else {
						this.reset();
					}
				});
			} else {
				this.reset();
			}
		}, 200),
		reset() {
			this.items = [];
		}
	},
	mounted() {
		this.model.siteId = this.siteId;
		this.model.lang = this.$i18n.locale;
		this.model.atmBranch = true;
	}
};
