import Vue from 'vue';
import { computed, ref, watch } from 'vue';

const COOKIE_USER_USES_NEW_IB = 'portal-netbank-uses-new-ib';
const NEW_NETBANK_URLS = {
	hu: 'https://internetbank.otpbank.hu/auth/hu/bejelentkezes?url=%2Ffooldal',
	en: 'https://internetbank.otpbank.hu/auth/en/bejelentkezes'
};
const OLD_NETBANK_URLS = {
	hu: '/portal/hu/OTPdirekt/Belepes',
	en: '/portal/en/OTPdirektlogin'
};

export const useMenu = (locale, defaultSegment) => {
	const mobileMenuData = window.context.mobileMenu;
	const segments = ref(mobileMenuData.segments.map((item) => ({ name: item.name, url: item.url })));
	const segment = ref(window.context.segment);
	const path = ref([]);
	const service = ref(null);
	const responsiveFrame = window.context.responsiveFrame;

	const usesNewIb = Vue.cookie.get(COOKIE_USER_USES_NEW_IB);
	const userUsesNewIb = usesNewIb == 'true';
	const direktLink = computed(() => {
		return userUsesNewIb ? NEW_NETBANK_URLS[locale.value] : OLD_NETBANK_URLS[locale.value];
	});

	const menuButtonVisible = ref(true);
	const getMenuData = () => {
		return mobileMenuData.segments.find((item) => item.url == segment.value);
	};
	const menuData = computed(() => {
		const currentMenuData = getMenuData();
		if (currentMenuData) {
			return currentMenuData;
		} else {
			segment.value = defaultSegment;
			return getMenuData();
		}
	});
	watch(menuData, () => {
		path.value = [];
	});
	const menu = computed(() => {
		let menuItemList = menuData.value;
		path.value.forEach((pathPart) => {
			menuItemList = menuItemList.menuItems.find((item) => item.name == pathPart);
		});
		return menuItemList;
	});
	const menuItems = computed(() => {
		return menu?.value?.menuItems;
	});
	const menuName = computed(() => {
		return menu?.value?.name;
	});
	const mobileApps = computed(() => {
		return menu?.value?.mobileAppList;
	});
	const goToUrl = () => {
		menuButtonVisible.value = false;
		window.location.href = service.value;
	};
	const goBack = () => {
		path.value.splice(-1);
	};
	const isRootLevel = computed(() => !path.value.length);
	const addPath = (menuPath) => {
		path.value.push(menuPath);
	};
	return {
		segment,
		segments,
		menuItems,
		mobileApps,
		isRootLevel,
		menuName,
		service,
		responsiveFrame,
		direktLink,
		menuButtonVisible,
		goToUrl,
		goBack,
		addPath
	};
};
