import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancel;
export default {
	cancelSuggestion() {
		if (cancel) {
			cancel();
		}
	},
	getSuggestions(token, language) {
		this.cancelSuggestion();
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.get(`/suggest/${language}`, {
					cancelToken: new CancelToken((c) => {
						cancel = c;
					}),
					params: {
						token
					}
				});
				resolve(response);
			} catch (err) {
				if (axios.isCancel(err)) {
					resolve();
				} else {
					reject(err);
				}
			}
		});
	},
	search(query, language, page = 0) {
		return axios.get(`/search/${language}`, {
			params: {
				query,
				page
			}
		});
	}
};
