import axios from 'axios';
import { Client } from '@stomp/stompjs';
const getUrl = () => {
	const schema = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
	const url = `${schema}//${window.location.host}/notification/public/notification`;
	return url;
};
export default {
	createWSClient(stompConfig) {
		stompConfig.brokerURL = getUrl();
		return new Client(stompConfig);
	},
	getFallbackMessage(language) {
		const date = new Date().getTime();
		return axios.get(`/static/portal/json/notifications_${language}.json?_t=${date}`);
	},
	getPreview(notificationId) {
		return axios.get(`/notification/private/${notificationId}`);
	}
};
