import Notification from '../../../common/components/notification/notification.vue';
import SearchWrapper from '../header-commons/search-wrapper/search-wrapper.vue';
import MegaMenu from '../header-commons/megamenu/megamenu';
import Sectionbar from '../header-commons/sectionbar/sectionbar.vue';
import Searchbar from '../header-commons/searchbar/searchbar.vue';
import headerBase from '../header-commons/lib/header-base';
const HAMBURGER_COLOR_CLOSED = '#FFFFFF';
const HAMBURGER_COLOR_OPENED = '#1B1F27';
const COOKIE_USER_USES_NEW_IB = 'portal-netbank-uses-new-ib';
const NEW_NETBANK_URLS = {
	hu: 'https://internetbank.otpbank.hu/auth/hu/bejelentkezes?url=%2Ffooldal',
	en: 'https://internetbank.otpbank.hu/auth/en/bejelentkezes'
};
export default {
	extends: headerBase,
	data: {
		segment: null,
		service: null,
		servicesVisible: false,
		toggledSection: null,
		responsiveFrame: null,
		direktLogin: null,
		userUsesNewIb: false
	},
	methods: {
		goToUrl(e) {
			window.location.href = e.target.value;
		},
		searchToggle() {
			this.toggledSection = this.toggledSection == 'search' ? null : 'search';
			if (this.toggledSection == 'search') {
				this.$nextTick(() => {
					this.$refs['main-search'].$refs['search-input'].focus();
				});
			}
		},
		navigationToggle() {
			this.toggledSection = this.toggledSection == 'navigation' ? null : 'navigation';
		}
	},
	created() {
		this.segment = window.context.segment;
		this.responsiveFrame = window.context.responsiveFrame;
		this.direktLogin = window.context.direktLogin;
		const usesNewIb = this.$cookie.get(COOKIE_USER_USES_NEW_IB);
		this.userUsesNewIb = usesNewIb == 'true';
	},
	computed: {
		searchbarVisible() {
			return this.toggledSection == 'search';
		},
		navigationbarVisible() {
			return this.toggledSection == 'navigation';
		},
		hamburgerStrokeColor() {
			return this.navigationbarVisible || this.searchbarVisible ? HAMBURGER_COLOR_OPENED : HAMBURGER_COLOR_CLOSED;
		},
		direktLink() {
			return this.userUsesNewIb ? NEW_NETBANK_URLS[this.$i18n.locale] : this.direktLogin;
		}
	},
	components: {
		notification: Notification,
		SearchWrapper,
		MegaMenu,
		Sectionbar,
		Searchbar
	}
};
