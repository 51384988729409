import VueFocus from 'vue-focus';
import isTouchDevice from 'is-touch-device';
import VueButton from '@ui-common/vue-button';
export default {
	mixins: [VueFocus.mixin],
	data() {
		return {
			openedMenu: null,
			focusedMenu: null,
			menuTimer: null,
			activeMenuId: null
		};
	},
	components: {
		VueButton
	},
	methods: {
		openMenu(menuId, hover, e) {
			// tablet eseten nincs lenyilas
			if (isTouchDevice() && !hover) {
				let href = e.currentTarget.getAttribute('href');
				window.location.href = href;
			} else if (menuId == this.openedMenu) {
				if (!hover) {
					let href = e.currentTarget.getAttribute('href');
					window.location.href = href;
				}
			} else {
				clearTimeout(this.menuTimer);
				if (hover) {
					this.menuTimer = setTimeout(() => {
						this.openedMenu = menuId;
					}, 300);
					this.focusedMenu = null;
				} else {
					this.openedMenu = menuId;
				}
			}
		},
		focusMenu(menuId) {
			if (this.openedMenu != null) {
				this.openedMenu = menuId;
			}
			this.focusedMenu = menuId;
		},
		closeMenu() {
			clearTimeout(this.menuTimer);
			this.openedMenu = null;
			this.focusedMenu = null;
		},
		isMenuOpened(menuId) {
			return this.openedMenu == menuId;
		},
		handleEscKey(event) {
			if ((this.openedMenu ?? -1) >= 0 && event.key === 'Escape') {
				this.closeMenu();
			}
		}
	},
	mounted() {
		this.activeMenuId = window.context.activeMenuId;
		window.addEventListener('keydown', this.handleEscKey);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.handleEscKey);
	}
};
